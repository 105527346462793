import { getAddress } from 'viem'
import memoize from 'lodash/memoize'
import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.POLYGON_ZKEVM]: 'polygonzkevm',
  [ChainId.ZKSYNC]: 'zksync',
  [ChainId.ARBITRUM_ONE]: 'arbitrum',
}

const TOKENS_LOGO_MAP: Record<string, string> = {
  MATIC: 'https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png',
  AVAX: 'https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png',
  BNB: 'https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png',
  WETH: 'https://upload.wikimedia.org/wikipedia/commons/6/6f/Ethereum-icon-purple.svg',
  USDT: 'https://cryptologos.cc/logos/tether-usdt-logo.png?v=025',
  USDC: 'https://cryptologos.cc/logos/usd-coin-usdc-logo.png?v=025',
}

const getTokenLogoURL = memoize(
  (token?: Token) => {
    if (token.chainId === ChainId.MANTA_MAINNET) {
      return TOKENS_LOGO_MAP[token.symbol] ?? 'https://l2beat.com/icons/mantapacific.png'
    }

    if (token && mapping[token.chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[token.chainId]}/assets/${getAddress(
        token.address,
      )}/logo.png`
    }
    return null
  },
  (t) => `${t.chainId}#${t.address}`,
)

export const getTokenLogoURLByAddress = memoize(
  (address?: string, chainId?: number) => {
    if (address && chainId && mapping[chainId]) {
      return `https://assets-cdn.trustwallet.com/blockchains/${mapping[chainId]}/assets/${getAddress(address)}/logo.png`
    }
    return null
  },
  (address, chainId) => `${chainId}#${address}`,
)

export default getTokenLogoURL
